import {Component} from '@angular/core';
import {FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';

import {AuthService} from '../../auth.service';
import {GlobalService} from 'src/app/services/global.service';
import {NumberOfUnits} from 'src/app/pages/account-information';
import {TermsAndConditionModalComponent} from './terms-and-condition/terms-and-condition.modal';
import {PrivacyPoliciesModalComponent} from './privacy-policies/privacy-policies.modal';

@Component({
    selector: 'app-sign-up-form',
    templateUrl: './sign-up.component.html',
    styleUrls: ['../forms-shared.scss'],
})
export class SignUpComponent {
    /** user sign up form group */
    form = this.initForm();
    hide = true;
    confirmPasswordHide = true;
    triggerTermsAndConditionError = false;
    /** constructor */
    constructor(
        public readonly global: GlobalService,
        private readonly formBuilder: FormBuilder,
        private readonly authService: AuthService
    ) {}

    showValidationMessages = false;
    hideTimeout: any;

    checkTermsAndConditionsAreAccepted = false;
    protected noOfUnits = Object.keys(NumberOfUnits);

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * handle user sign up flow.
     */
    signUp() {
        // check if the form is invalid.
        // mark all form fields as touched to trigger validation messages.
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }
        const numberOfUnits = this.form.value.numberOfUnits === '' ? null : this.form.value.numberOfUnits;
        const payload = {
            firstName: this.form.value.firstName,
            lastName: this.form.value.lastName,
            phoneNumber: this.form.value.phoneNumber,
            email: this.form.value.email,
            password: this.form.value.password,
            numberOfBuildings: parseInt(this.form.value.numberOfBuildings, 10),
            numberOfUnits: numberOfUnits,
        };
        if (this.checkTermsAndConditionsAreAccepted) {
            this.authService.signUp(payload);
        } else {
            this.triggerTermsAndConditionError = true;
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * initializes and returns a form group for user sign up
     *
     * @returns FormGroup instance for user sign up
     */
    private initForm() {
        return this.formBuilder.group(
            {
                email: ['', [Validators.required, Validators.email]],
                phoneNumber: ['', [Validators.required]],
                password: [
                    '',
                    [
                        Validators.required,
                        Validators.minLength(8), // Minimum length of 8 characters
                        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).*$/),
                        // Include at least one lowercase letter, one uppercase letter, and one number
                    ],
                ],
                confirmPassword: ['', [Validators.required]],
                firstName: ['', Validators.required],
                lastName: ['', Validators.required],
                numberOfBuildings: [''],
                verificationRequired: true,
                numberOfUnits: [''],
                phoneNumberExtension: [''],
            },
            {validator: this.passwordMatchValidator()}
        );
    }

    async triggerTermsAndCondition(event: Event) {
        event.preventDefault();
        const modal: HTMLIonModalElement = await this.global.modalCtrl.create({
            component: TermsAndConditionModalComponent,
            componentProps: {},
            cssClass: 'auto-height rounded-sm',
        });
        this.global.openedModals.push(modal);
        modal.onDidDismiss().then((detail) => {
            this.global.openedModals.pop();
        });

        await modal.present();
    }

    async triggerPrivacyPolicy(event: Event) {
        event.preventDefault();
        const modal: HTMLIonModalElement = await this.global.modalCtrl.create({
            component: PrivacyPoliciesModalComponent,
            componentProps: {},
            cssClass: 'auto-height rounded-sm',
        });
        this.global.openedModals.push(modal);
        modal.onDidDismiss().then((detail) => {
            this.global.openedModals.pop();
        });

        await modal.present();
    }

    valueChange(event: any) {
        this.checkTermsAndConditionsAreAccepted = event.source.checked;
        if (this.checkTermsAndConditionsAreAccepted) {
            this.triggerTermsAndConditionError = false;
        } else {
            this.triggerTermsAndConditionError = true;
        }
    }

    private passwordMatchValidator(): ValidatorFn {
        return (formGroup: FormGroup): ValidationErrors | null => {
            const password = formGroup.get('password').value;
            const confirmPassword = formGroup.get('confirmPassword').value;

            if (password !== confirmPassword) {
                formGroup.get('confirmPassword').setErrors({passwordMismatch: true});
                return {passwordMismatch: true};
            } else {
                return null;
            }
        };
    }

    checkPasswordValidation(target) {
        const password = target?.value;
        this.global.isPasswordValid.minLength = password.length >= 8;
        this.global.isPasswordValid.lowerCase = /[a-z]/.test(password);
        this.global.isPasswordValid.upperCase = /[A-Z]/.test(password);
        // eslint-disable-next-line id-blacklist
        this.global.isPasswordValid.number = /\d/.test(password);
        this.global.isPasswordValid.symbol = this.global.allowedSymbols.test(password);

        const allValid =
            this.global.isPasswordValid.minLength &&
            this.global.isPasswordValid.lowerCase &&
            this.global.isPasswordValid.upperCase &&
            this.global.isPasswordValid.number &&
            this.global.isPasswordValid.symbol;
        if (allValid) {
            clearTimeout(this.hideTimeout);
            this.hideTimeout = setTimeout(() => {
                this.showValidationMessages = false;
            }, 1000);
        } else {
            clearTimeout(this.hideTimeout);
            this.showValidationMessages = true;
        }
    }
}
