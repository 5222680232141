import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiResponseService} from 'src/app/services/api-response.service';
import {KYCService} from '../../kyc.service';
import {GlobalService} from 'src/app/services/global.service';

@Component({
    selector: 'app-company-type-select-step',
    templateUrl: './company-type-select-step.component.html',
    styleUrls: ['./company-type-select-step.component.scss'],
})
export class CompanyTypeSelectStepComponent implements OnInit {
    constructor(
        public fb: FormBuilder,
        public apiService: ApiResponseService,
        public kycDataService: KYCService,
        public global: GlobalService
    ) {}
    currentKycCompanyId = '';
    kycForm: FormGroup;
    kycInfo: {companyTypeId: string; otherEntityType: string} = {companyTypeId: '', otherEntityType: ''};
    isKycCompleted = false;
    accountType;
    @Output() isStepComplete = new EventEmitter<boolean>();
    ngOnInit() {
        this.kycDataService.disableNextBtn = false;

        this.kycForm = this.fb.group({
            companyTypeId: ['', Validators.required],
            otherEntityType: ['', [Validators.required, this.noWhitespaceValidator]],
        });
        this.apiService.getCompanyTypes().subscribe((response) => {
            this.accountType = response;
        });
        this.apiService.getKycBaseDetails().subscribe((response) => {
            this.kycForm.get('companyTypeId').setValue(response.companyInfo.companyTypeId);
            this.kycForm.get('otherEntityType').setValue(response.companyInfo.otherEntityType);
            this.kycDataService.updateCurrentKycCompanyId(response.companyInfo.companyTypeId);
        });
    }

    /**
     * @summary checks if the company type is selected
     * @param value
     */
    AccountTypeOnClick(value: string): void {
        this.kycForm.get('companyTypeId').setValue(value);
        this.currentKycCompanyId = value;

        this.kycDataService.updateCurrentKycCompanyId(value);
    }

    async onSubmit() {
        if (this.kycForm.get('companyTypeId').value || this.kycForm.get('otherEntityType').value) {
            if (
                (this.kycForm.get('companyTypeId').value === '5' && this.kycForm.get('otherEntityType').valid) ||
                this.kycForm.get('companyTypeId').value !== '5'
            ) {
                // Step 1.2: Set KYC info values
                this.kycInfo['companyTypeId'] = this.kycForm.get('companyTypeId').value;
                this.kycInfo['otherEntityType'] = this.kycForm.get('otherEntityType').value;
                this.kycDataService.updateStepValidity('accountType', true);
                // Step 1.3: Send KYC details to server
                if (!this.isKycCompleted) {
                    const response = await this.apiService.sendKycDetails(this.kycInfo);
                    this.kycDataService.updateCurrentKYCID(response.kycId);
                    this.kycDataService.updateCurrentKycCompanyId(this.currentKycCompanyId);
                } else {
                    this.kycDataService.updateStepValidity('accountType', false);
                }
            } else {
                // Step 1.5: Mark personal info fields as touched
                this.kycForm.get('otherEntityType').markAllAsTouched();
                this.kycDataService.updateStepValidity('accountType', false);
            }
        } else {
            this.global.basicAlert('', 'Please Select any one company type.');
        }
    }

    /**
     * Custom validator function that checks if a control's value contains only whitespace characters.
     * @param control - The form control to validate.
     * @returns An object with a `whitespace` property set to `true` if the value contains only whitespace, or `null` otherwise.
     */
    noWhitespaceValidator(control: AbstractControl): {[key: string]: any} | null {
        const isWhitespace = (control.value || '').trim().length === 0;
        return isWhitespace ? {whitespace: true} : null;
    }
}
