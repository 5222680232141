<form [formGroup]="form" class="form-v2">
    <!-- old password -->
    <app-form-field label="label.old_password">
        <mat-form-field>
            <input
                matInput
                type="password"
                [placeholder]="'label.old_password' | translate"
                formControlName="oldPassword" />
            <mat-error appFieldErrors></mat-error>
        </mat-form-field>
    </app-form-field>

    <!-- new password and confirm password -->
    <ion-grid class="info-grid-v2">
        <ion-row class="align-items-baseline">
            <!-- new password -->
            <ion-col size="12" size-sm="6">
                <app-form-field label="label.new_password">
                    <mat-form-field>
                        <input
                            matInput
                            [type]="hide ? 'password' : 'text'"
                            (input)="checkPasswordValidation($event.target)"
                            type="password"
                            [placeholder]="'label.new_password' | translate"
                            formControlName="newPassword" />
                        <ion-icon
                            matSuffix
                            (click)="hide = !hide"
                            name="{{ hide ? 'eye-off' : 'eye' }}"
                            style="font-size: 16px; cursor: pointer"></ion-icon>
                        <mat-error appFieldErrors></mat-error>
                    </mat-form-field>
                    <div *ngIf="showValidationMessages" class="validation-list">
                        <div
                            [ngClass]="{
                                valid: global.isPasswordValid.minLength,
                                invalid: !global.isPasswordValid.minLength
                            }">
                            <span>{{ global.isPasswordValid.minLength ? '✔️' : '❌' }}</span> At least 8 characters long
                        </div>
                        <div
                            [ngClass]="{
                                valid: global.isPasswordValid.lowerCase,
                                invalid: !global.isPasswordValid.lowerCase
                            }">
                            <span>{{ global.isPasswordValid.lowerCase ? '✔️' : '❌' }}</span> At least one lowercase
                            letter
                        </div>
                        <div
                            [ngClass]="{
                                valid: global.isPasswordValid.upperCase,
                                invalid: !global.isPasswordValid.upperCase
                            }">
                            <span>{{ global.isPasswordValid.upperCase ? '✔️' : '❌' }}</span> At least one uppercase
                            letter
                        </div>
                        <div
                            [ngClass]="{valid: global.isPasswordValid.number, invalid: !global.isPasswordValid.number}">
                            <span>{{ global.isPasswordValid.number ? '✔️' : '❌' }}</span> Contains at least one number
                        </div>
                        <div
                            [ngClass]="{valid: global.isPasswordValid.symbol, invalid: !global.isPasswordValid.symbol}">
                            <span>{{ global.isPasswordValid.symbol ? '✔️' : '❌' }}</span> Contains at least one symbol
                            (!@#$%^&*)
                        </div>
                    </div>
                </app-form-field>
            </ion-col>

            <!-- confirm password -->
            <ion-col size="12" size-sm="6">
                <app-form-field label="label.confirm_password">
                    <mat-form-field>
                        <input
                            matInput
                            type="password"
                            [placeholder]="'label.confirm_password' | translate"
                            formControlName="confirmPassword" />
                        <mat-error appFieldErrors></mat-error>
                    </mat-form-field>
                </app-form-field>
            </ion-col>
        </ion-row>
    </ion-grid>
</form>
