import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {GlobalService} from '../../../services/global.service';
import {KYCService} from '../kyc.service';
import {AbstractControl, FormBuilder, FormGroup} from '@angular/forms';
import {
    canadianProvinces,
    genderOptions,
    individualSteps,
    nonIndividualSteps,
    thirdPartyManagmentOptions,
    getKycStepNavigation,
} from '../kyc.constant';
import {ApiResponseService} from 'src/app/services/api-response.service';
import {MatStepper} from '@angular/material/stepper';
import {KycStakeholdersPage} from './kyc-stakeholders/kyc-stakeholders.page';
import {TranslateService} from '@ngx-translate/core';
import {FileUploaderComponent} from 'src/app/components/file-uploader/file-uploader.component';
import {Subscription} from 'rxjs';
import {StakeholdersAcknowledgementModal} from './stakeholders-acknowledgement/stakeholders-acknowledgement.modal.ts/stakeholders-acknowledgement.modal';

@Component({
    selector: 'app-kyc-portal',
    templateUrl: './kyc-portal.page.html',
    styleUrls: ['./kyc-portal.page.scss'],
})
export class KYCPortalPage implements OnInit {
    @ViewChild('StakeHolderGrid', {static: false}) stakeholderGrid: KycStakeholdersPage;
    @ViewChild('fileUploader', {static: false}) fileUploader: FileUploaderComponent;
    @ViewChild('RecordOfExistencefileUploader', {static: false}) recordOfExistencefileUploader: FileUploaderComponent;
    @ViewChild('stepper')
    stepper: MatStepper;
    title = 'Kyc';
    step = 1;
    public uploadedFilesOptions: {externalButton: boolean};
    kycForm: FormGroup;
    accountType: any[] = [];
    thirdPartyManagmentOption: any[] = thirdPartyManagmentOptions;
    employmentSectors;
    businessTypes: any[] = [];
    accountingSoftwares: any[] = [
        {
            accountingSoftwareTypeId: '0',
            value: 'None',
        },
    ];
    kycSteps: {step: string; isStepValid: boolean}[];
    filesToSend: any[] = [];
    recordOfExistencefile: any[] = [];
    public triggerExternalClickCount = 0;
    public triggerRecordOfExistencefileClickCount = 0;
    legalCapacityAppliedn = false;
    photoUploadReq: any;
    public googleAddress = true;
    kycInfo: any = {};

    lastSavedkycDetails: any;
    isUsingAccountingSoftware: any;
    today: Date = new Date();
    apartmentSelected: any[] = [];
    coopTypes: any[] = [];
    showRestOfTheSteps = false;
    genders = genderOptions;
    individualSteps = individualSteps;
    idvCheckLink: string;
    nonIndividualSteps = nonIndividualSteps;
    showMailingAddress = true;
    isInformationVerifiedChecked = false;
    activeChildComponent;
    isKycCompleted: 1 | 0 = 0;
    canadianProvincesOptions = canadianProvinces;
    nationalitiesOptions;
    currentKycId: number;
    companyTypeId: string;
    hideAddressFields = false;
    stakeholdersAcknowledged = false;
    hideMailingAddressFields = true;
    triggerTermsAndConditionError = false;
    triggerDocumentUploadError = false;
    private dataSubscription: Subscription;
    private kycCompanySubscription: Subscription;
    private currentNavigationalStep: number;

    constructor(
        public global: GlobalService,
        public route: ActivatedRoute,
        public translate: TranslateService,
        public fb: FormBuilder,
        public apiService: ApiResponseService,
        public kycDataService: KYCService,
        private router: Router
    ) {
        this.router.events.subscribe((event: NavigationStart) => {
            if (!this.isKycCompleted) {
                if (event.navigationTrigger === 'popstate') {
                    const previousStep = this.step;
                    this.currentNavigationalStep = getKycStepNavigation(kycDataService.incompleteBuildingDetails).find(
                        (x) => x.path === event.url.split('/')[2]
                    )?.step;
                    if (previousStep >= this.currentNavigationalStep) {
                        this.updateStep('back');
                        this.handleBrowserRefresh();
                    } else {
                        this.handleBrowserRefresh();
                    }
                }
            }
        });
    }

    //create a function to return heading individualSteps or nonIndividualSteps
    getHeadingByStep(companyType): string {
        const heading = companyType.find((x) => x.step === this.step)?.heading;
        return heading ? heading : '';
    }
    ngOnDestroy() {
        this.dataSubscription.unsubscribe();
        this.kycCompanySubscription.unsubscribe();
    }

    ngOnInit() {
        this.kycDataService.checkIncompleteBuildings();
        this.dataSubscription = this.kycDataService.kycStep$.subscribe((data) => {
            this.kycSteps = data;
        });
        this.apiService.getKycBaseDetails().subscribe({
            next: (response) => {
                this.kycDataService.currentKYCID = response?.kycInfo?.kycId;
                this.isKycCompleted = response?.kycInfo?.completed;
                this.companyTypeId = this.companyTypeId ? this.companyTypeId : response.companyInfo.companyTypeId;
                this.handleBrowserRefresh();
            },
            error: () => {
                // Handle error here
                this.handleBrowserRefresh();
                // Display error message to the user or perform any other error handling logic
            },
        });

        this.kycCompanySubscription = this.kycDataService.curentKycCompanyId$.subscribe((data) => {
            this.companyTypeId = data;
        });

        this.step = sessionStorage.getItem('kycStep') ? parseInt(sessionStorage.getItem('kycStep'), 10) : 1;

        if (this.step > 1) {
            this.showRestOfTheSteps = true;
        }
    }

    isStepSixAndCompanyTypeOne(): boolean {
        if (this.kycDataService.incompleteBuildingDetails) {
            return this.step === 6 && this.companyTypeId === '1';
        } else {
            return this.step === 5 && this.companyTypeId === '1';
        }
    }

    /**
     * Checks if the current step is 6 and the company type is not 1.
     *
     * @returns {boolean} True if the current step is 6 and the company type is not 1, false otherwise.
     */ z;
    isStepSevenAndCompanyTypeNotOne(): boolean {
        if (this.kycDataService.incompleteBuildingDetails) {
            return this.step === 7 && this.companyTypeId !== '1';
        } else {
            return this.step === 6 && this.companyTypeId !== '1';
        }
    }

    hideBreadCrumb() {
        if (this.kycDataService.incompleteBuildingDetails) {
            return (
                (this.companyTypeId === '1' && this.step === 5) ||
                (this.companyTypeId !== '1' && this.step === 6) ||
                this.step === 1
            );
        } else {
            return (
                (this.companyTypeId === '1' && this.step === 4) ||
                (this.companyTypeId !== '1' && this.step === 5) ||
                this.step === 1
            );
        }
    }

    /**
     * Determines whether the element should be shown based on the current step and company type.
     *
     * @returns A boolean value indicating whether the element should be shown.
     */
    shouldShowElement(): boolean {
        return !(this.step === 1 || this.isStepSixAndCompanyTypeOne() || this.isStepSevenAndCompanyTypeNotOne());
    }

    showNextStep() {
        return !(this.isStepSixAndCompanyTypeOne() || this.isStepSevenAndCompanyTypeNotOne());
    }

    /**
     * Handles the browser refresh event and navigates to the appropriate page based on the current step and KYC completion status.
     */
    handleBrowserRefresh() {
        if (this.isKycCompleted !== 1) {
            switch (this.step) {
                case 1:
                    this.currentNavigationalStep = this.currentNavigationalStep ?? 1;
                    this.router.navigate(['/kyc-portal/account-type']);
                    break;
                case 2:
                    this.currentNavigationalStep = this.currentNavigationalStep ?? 2;
                    this.router.navigate(['/kyc-portal/business-info']);
                    break;
                case 3:
                    this.currentNavigationalStep = this.currentNavigationalStep ?? 3;
                    this.router.navigate(['/kyc-portal/company-meta-data']);
                    break;
                case 4:
                    if (this.kycDataService.incompleteBuildingDetails) {
                        if (this.companyTypeId === '1') {
                            this.currentNavigationalStep = this.currentNavigationalStep ?? 5;
                            this.router.navigate(['/kyc-portal/building-details']);
                        } else {
                            this.currentNavigationalStep = this.currentNavigationalStep ?? 4;
                            this.router.navigate(['/kyc-portal/stakeholder-info']);
                        }
                    } else {
                        if (this.companyTypeId === '1') {
                            this.currentNavigationalStep = this.currentNavigationalStep ?? 6;
                            this.router.navigate(['/kyc-portal/application-details']);
                        } else {
                            this.currentNavigationalStep = this.currentNavigationalStep ?? 4;
                            this.router.navigate(['/kyc-portal/stakeholder-info']);
                        }
                    }
                    break;
                case 5:
                    if (this.kycDataService.incompleteBuildingDetails) {
                        if (this.companyTypeId === '1') {
                            this.currentNavigationalStep = this.currentNavigationalStep ?? 6;
                            this.router.navigate(['/kyc-portal/application-details']);
                        } else {
                            this.currentNavigationalStep = this.currentNavigationalStep ?? 5;
                            this.router.navigate(['/kyc-portal/building-details']);
                        }
                    } else {
                        if (this.companyTypeId === '1') {
                            this.currentNavigationalStep = this.currentNavigationalStep ?? 7;
                            this.isKycCompleted = 1;
                            this.router.navigate(['/kyc-portal/identity-verfication']);
                        } else {
                            this.currentNavigationalStep = this.currentNavigationalStep ?? 6;
                            this.router.navigate(['/kyc-portal/application-details']);
                        }
                    }

                    break;
                case 6:
                    if (this.kycDataService.incompleteBuildingDetails) {
                        if (this.companyTypeId === '1') {
                            this.currentNavigationalStep = this.currentNavigationalStep ?? 7;
                            this.router.navigate(['/kyc-portal/identity-verfication']);
                        } else {
                            this.currentNavigationalStep = this.currentNavigationalStep ?? 6;
                            this.router.navigate(['/kyc-portal/application-details']);
                        }
                    } else {
                        this.currentNavigationalStep = this.currentNavigationalStep ?? 7;
                        this.isKycCompleted = 1;
                        this.router.navigate(['/kyc-portal/identity-verfication']);
                    }
                    break;

                case 7:
                    this.currentNavigationalStep = this.currentNavigationalStep ?? 7;
                    this.isKycCompleted = 1;
                    if (this.companyTypeId !== '1') {
                        this.router.navigate(['/kyc-portal/identity-verfication']);
                    }
                    break;
            }
        } else {
            this.router.navigate(['/kyc-portal/identity-verfication']);
            sessionStorage.clear();
            if (this.companyTypeId !== '1') {
                this.step = 6;
            } else this.step = 5;
        }
    }
    /**
     * Updates the current step in the KYC portal.
     * @param method - The method to update the step. Can be 'next' or 'back'.
     * @returns void
     */
    updateStep(method: 'next' | 'back'): void {
        if (method === 'next') {
            if (this.step < 7) {
                this.step += 1;
                this.currentNavigationalStep += 1;
                this.stepper?.next();
            }
        } else {
            if (this.step > 1) {
                this.step -= 1;
                this.currentNavigationalStep -= 1;
                this.stepper?.previous();
            }
        }
        sessionStorage.setItem('kycStep', this.step.toString());
    }

    onActivate(component: any) {
        this.activeChildComponent = component;
    }

    validateMonthlyTransactionAmount(control: AbstractControl): {[key: string]: any} | null {
        const value = control.value;
        if (value === 0) {
            control.setValue(null);
        }
        return value && (value <= 0 || value > 10000000000) ? {invalidAmount: true} : null;
    }
    /**
     * Custom validator function that checks if a control's value contains only whitespace characters.
     *
     * @param control - The form control to validate.
     * @returns An object with a `whitespace` property set to `true` if the value contains only whitespace, or `null` otherwise.
     */
    noWhitespaceValidator(control: AbstractControl): {[key: string]: any} | null {
        const isWhitespace = (control.value || '').trim().length === 0;
        return isWhitespace ? {whitespace: true} : null;
    }

    /**
     * Retrieves the company type based on the selected company type ID.
     * @returns The company type as a string.
     */
    getCompanyType(): string {
        if (this.accountType.length > 0)
            return this.accountType.find((x) => x.companyTypeId === this.companyTypeId).value;
    }

    /**
     * Displays the monthly transaction information.
     */
    monthlyTransactionInfo() {
        return this.translate.instant('label.monthly_transaction_amount_info');
        // this.global.basicAlert('', 'label.monthly_transaction_amount_info', true);
    }

    /**
     * Performs the next step in the KYC process based on the current step.
     *
     * @returns A Promise that resolves when the next step is completed.
     */
    async nextStep(): Promise<void> {
        this.kycDataService.disableNextBtn = true;
        this.global.showLoading();
        try {
            const onSubmitResult = this.activeChildComponent.onSubmit();
            if (onSubmitResult) {
                // Await the promise if it's not null or undefined
                await onSubmitResult.then(() => {
                    this.kycDataService.disableNextBtn = false;
                });
            } else {
                this.kycDataService.disableNextBtn = false;
            }
        } catch (error) {
            this.global.hideLoading();
            this.kycDataService.disableNextBtn = false;
        } finally {
            this.kycDataService.disableNextBtn = false;
            this.global.hideLoading();
        }

        const companyTypeId = this.companyTypeId;
        switch (this.step) {
            case 1:
                // Step 1: Check if company type is individual

                // Step 1.1: Check if personal info fields are valid
                if (this.kycSteps[0].isStepValid) {
                    this.updateStep('next');
                    // this.kycDataService.updateCurrentKYCID(t)
                    this.router.navigate(['/kyc-portal/business-info']);
                } else {
                    // Step 1.5: Mark personal info fields as touched
                    this.kycForm?.get('otherEntityType').markAllAsTouched();
                }
                break;
            case 2:
                // Step 3: Check if personal info, mailing address, and address fields are valid
                if (this.kycSteps[1].isStepValid) {
                    // Step 3.1: Get form values
                    this.updateStep('next');
                    this.router.navigate(['/kyc-portal/company-meta-data']);
                } else {
                }
                break;
            case 3:
                if (this.kycSteps[2].isStepValid) {
                    this.updateStep('next');
                    if (companyTypeId !== '1') {
                        this.router.navigate(['/kyc-portal/stakeholder-info']);
                    } else {
                        if (this.kycDataService.incompleteBuildingDetails) {
                            this.router.navigate(['/kyc-portal/building-details']);
                        } else {
                            this.router.navigate(['/kyc-portal/application-details']);
                        }
                    }
                } else {
                    // this.kycForm.get('companyMetaData').markAllAsTouched();
                    // this.kycForm.get('companyRealEstate').markAllAsTouched();
                }
                break;
            case 4:
                if (companyTypeId === '1') {
                    if (this.kycDataService.incompleteBuildingDetails) {
                        this.router.navigate(['/kyc-portal/application-details']);
                    } else {
                        if (this.kycSteps[4].isStepValid) {
                            this.router.navigate(['/kyc-portal/identity-verfication']);
                        }
                    }
                    this.updateStep('next');
                } else if (companyTypeId !== '1') {
                    if (this.kycDataService.atLeastOneStakeholder) {
                        const isAcknowledged = await this.stakeholdersAcknowledgement();
                        if (isAcknowledged) {
                            this.updateStep('next');
                            if (this.kycDataService.incompleteBuildingDetails) {
                                this.router.navigate(['/kyc-portal/building-details']);
                            } else {
                                this.router.navigate(['/kyc-portal/application-details']);
                            }
                        } else {
                            this.global.basicAlert(
                                '',
                                'Please confirm that you have acknowledged the provided information before continuing.',
                                true
                            );
                        }
                    } else {
                        this.global.basicAlert('', 'Please add at least one stakeholder', true);
                    }
                }
                break;
            case 5:
                if (companyTypeId !== '1') {
                    if (this.kycDataService.incompleteBuildingDetails) {
                        this.router.navigate(['/kyc-portal/application-details']);
                    } else {
                        if (this.kycSteps[4].isStepValid) this.router.navigate(['/kyc-portal/identity-verfication']);
                    }
                    this.updateStep('next');
                } else {
                }
                break;
            default:
                this.updateStep('next');
                break;
        }
    }

    async stakeholdersAcknowledgement(): Promise<boolean> {
        const modal: HTMLIonModalElement = await this.global.modalCtrl.create({
            component: StakeholdersAcknowledgementModal,
            componentProps: {companyTypeId: this.companyTypeId},
            cssClass: 'auto-height ion-disable-focus-trap',
        });

        await modal.present();
        const {data} = await modal.onDidDismiss();

        return data ? data.isChecked : false;
    }

    /**
     * @summary moves to previous step
     * @param value
     */
    previousStep(): void {
        switch (this.step) {
            case 6:
                this.router.navigate(['/kyc-portal/building-details']);
                break;
            case 5:
                if (this.companyTypeId !== '1') {
                    this.kycDataService.disableNextBtn = false;
                    this.router.navigate(['/kyc-portal/stakeholder-info']);
                } else {
                    this.router.navigate(['/kyc-portal/building-details']);
                }
                break;
            case 4:
                this.kycDataService.disableNextBtn = false;
                this.router.navigate(['/kyc-portal/company-meta-data']);
                break;
            case 3:
                this.router.navigate(['/kyc-portal/business-info']);
                break;
            case 2:
                this.router.navigate(['/kyc-portal/account-type']);
                break;
            default:
                break;
        }
        this.updateStep('back');
        // this.kycForm.markAsUntouched();
    }
}
