import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import {ApiResponseService} from 'src/app/services/api-response.service';
import {KYCService} from '../../kyc.service';
import {Subscription} from 'rxjs';
import {GlobalService} from 'src/app/services/global.service';

/**
 * Represents the Identification QR Code page component.
 */
@Component({
    selector: 'Identification-QR-code',
    templateUrl: './Identification-QR-code.page.html',
    styleUrls: ['./Identification-QR-code.page.scss'],
})
export class IdentificationQrCodePage implements OnInit, OnDestroy {
    /**
     * The company type input.
     */
    @Input() companyType;

    isLinkCopied = false;
    idvLinkGenerated = false;

    /**
     * The IDV check link input.
     */
    idvCheckLink = '';

    /**
     * Indicates whether the IDV check is completed or not.
     */
    idvCheckCompleted = false;

    kycCompanySubscription: Subscription;

    constructor(
        public apiService: ApiResponseService,
        public kycDataService: KYCService,
        public global: GlobalService
    ) {
        // addIcons({})
    }

    ngOnInit(): void {
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
        this.getBaseDetails();
    }
    getBaseDetails() {
        this.apiService.getKycBaseDetails().subscribe((response) => {
            this.companyType = response.companyInfo.companyTypeId;

            if (this.companyType === '1') {
                this.checkIdvCheckStatus(response?.kycInfo?.status);
            } else {
                if (this.global.pendingKyc) {
                    this.global.loginEssential(false);
                }
            }
            //  this.apiService.amlCheck().subscribe(() => {});
            /*   this.kycCompanySubscription = this.kycDataService.curentKycCompanyId$.subscribe((data) => {
                this.companyType = data;
            }); */
        });
    }
    ngOnDestroy(): void {
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    }

    getIdvLink() {
        this.global.showLoading();
        this.apiService.idvCheck().subscribe((idvLink) => {
            this.global.hideLoading();
            this.idvCheckLink = idvLink.webEnrolmentUrl;
            this.idvLinkGenerated = true;
        });
    }
    /**
     * Handles the visibility change event and checks the IDV check status.
     */
    handleVisibilityChange = (): void => {
        if (document.visibilityState === 'visible' && !this.idvCheckCompleted) {
            this.getBaseDetails();
        }
    };

    openTrusmatic() {
        window.open(this.idvCheckLink, '_blank');
    }

    copyLink() {
        if (this.idvCheckLink) {
            navigator.clipboard
                .writeText(this.idvCheckLink)
                .then(() => {
                    this.isLinkCopied = true;
                    this.global.basicAlert(
                        'Link Copied',
                        'The link has been copied. Feel free to paste it into your browser or share it with others.'
                    );

                    setTimeout(() => {
                        this.isLinkCopied = false;
                    }, 5000);
                })
                .catch((err) => {
                    console.error('Failed to copy link: ', err);
                });
        }
    }

    /**
     * Checks the IDV check status.
     */
    checkIdvCheckStatus(kycStatus): void {
        this.apiService.getIdvCheckStatus().subscribe((response) => {
            if (response.status === 'PENDING') {
                this.idvCheckLink = response.webEnrolmentUrl;
                this.idvLinkGenerated = true;
                return;
            }

            if (kycStatus === 'changeRequest') {
                if (response.message === 'data_not_found') {
                    this.idvCheckCompleted = false;
                    return;
                } else {
                    this.idvCheckCompleted = true;
                    return;
                }
            } else {
                if (response.status !== 'PENDING' && response.message !== 'data_not_found') {
                    if (this.global.pendingKyc) {
                        this.global.loginEssential(false);
                    }

                    this.idvCheckCompleted = true;
                } else {
                    this.idvCheckCompleted = false;
                }
            }
        });
    }

    startIdv() {
        this.getIdvLink();
    }
}
